@import url('https://fonts.googleapis.com/css?family=Roboto');

body {
  font-family: 'Roboto', sans-serif;
  background: #a7c4e6;
}

$color-my-message: #057eff;
$color-friend-message: #e5e5ea;

.screen {
  width: 100%;
  align-self: end;
  max-height: 100%;
  overflow-y: scroll;
  bottom: 0;
  position: absolute;
  align-items: end;
  color: #00000000;
  transition: color 0.3s, height 1s;

	&:hover {
		color: #666666FF;
	}
}

.screen::-webkit-scrollbar {
	width: 14px;
}

.screen::-webkit-scrollbar-thumb {
	background-clip: content-box;
	border: 4px solid transparent;
	border-radius: 7px;
	box-shadow: inset 0 0 0 10px;
}
.screen::-webkit-scrollbar-button {
	width: 0;
	height: 0;
	display: none;
}
.screen::-webkit-scrollbar-corner {
	background-color: transparent;
}

.bubble-container {
  margin: 0 15px 8px 10px;
  padding: 7px 10px;
  border-radius: 9px;
  background-color: $color-my-message;
  position: relative;
  color: white;

  &--friend {
    background-color: $color-friend-message;
    color: #1a1a1b;
    position: relative;
    margin: 0 10px 8px 15px;
    padding: 7px 10px;
    border-radius: 9px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 70%;
      width: 0;
      height: 0;
      border: 9px solid transparent;
      border-right-color: $color-friend-message;
      border-left: 0;
      border-top: 0;
      margin-top: -4.5px;
      margin-left: -9px;
    }
  }
}

.bubble-container:after {
	content: '';
	position: absolute;
	right: 0;
	top: 70%;
	width: 0;
	height: 0;
	border: 9px solid transparent;
	border-left-color: $color-my-message;
	border-right: 0;
	border-top: 0;
	margin-top: -4.5px;
  margin-right: -9px;
}

.cellphone {
  display: grid;
  grid-template-rows: 75px auto 55px;
  padding: 20px;
  background-color: #444444;
  border-radius: 13px;
  height: 620px;
  width: 350px;
  margin: 5vh auto 0 auto;
}

.screen-buffer {
  height: 600px;
}

.touch-container {
  display: grid;
  grid-template-rows: auto 55px;
}

.screen-container {
  background-color: white;
  position: relative;
}

.bubble-text-container {
  transition: width 0.5s, height 0.5s;
  min-width: 60px;
  max-width: 270px;
}

.bubble-text-container-container {
  width: 100%;
  display: flex;
  &--right {
    justify-content: flex-end;
  }
}

.button-container {
  background-color: #d0d3da;
  display: flex;
  justify-content: center;
  align-items: center;
}

.send-button {
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #868e99;
  border-radius: 15px;
  height: 40px;
  width: 120px;
  color: white;
  font-size: 20px;
  transition: height 0.3s, width 0.3s, background-color 0.3s;

  &:disabled {
    background-color: #e27a7a;
    cursor: default;
    color: rgba(255, 255, 255, 0.6);
  }

  &:enabled:hover {
    background-color: #696c70;
    height: 43px;
    width: 127px;
  }
}

.phone-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-button {
  content: ' ';
  background-color: #353535;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  margin-top: 10px;
}

.message-header {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-rows: 70% auto;
  background-color: #f0f0f1;
}

.friend-icon {
  background-color: #9095a0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.friend-name {
  align-self: start;
  font-size: 12px;
  text-align: center;
}